import React, { Component ,Fragment } from "react";
import BlogContent from "./BlogContent";

class BLogList extends Component{
    render() {
        return (
          <Fragment>
            <div className="container">
              <div className="row">
                {this.props.data.nodes.map((value, i) => (
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <div className="blog blog-style--1">
                      <div className="thumbnail">
                        <a href={`/journal/${value.url}`}>
                          <img
                            className="w-100"
                            src={`${value.mainImage ? value.mainImage.fixed.src : '/assets/images/pattern-3.png'}`}
                            alt={value.title}
                          />
                        </a>
                      </div>
                      <div className="content">
                        <p className="blogtype">{value.categoryGroup ? value.categoryGroup.category : ''}</p>
                        <h4 className="title">
                          <a href={`/journal/${value.url}`}>{value.title}</a>
                        </h4>
                        <div className="blog-btn">
                          <a className="rn-btn text-white" href={`/journal/${value.url}`}>
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        );
    }
}
export default BLogList;
import React, { Component , Fragment } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import TabTwo from "../elements/tab/TabTwo";
import TestimonialOne from "../blocks/testimonial/TestimonialOne";
import ContactThree from "../elements/contact/ContactThree";
import BlogList from "../elements/blog/BlogList";

  const contentOptions = {
    renderNode: {
      [BLOCKS.QUOTE]: node => {
        return (
          <blockquote className="blog-quote">
            <p style={{ alignSelf: "center" }}>
              {node.content[0].content[0].value}
            </p>
          </blockquote>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const type = node.data.target.sys.contentType.sys.id

        const { title, language, code, codeSnip } = node.data.target.fields

        switch (type) {
          case "codeSnippet":
            return (
              <section className="blog-code-snippet">
                <h4>{title["en-US"]}</h4>
                <pre
                  className={`embedded-entry language-jsx language-javascript ${type} ${language[
                    "en-US"
                  ].toLowerCase()}`}
                >
                  <code>{codeSnip["en-US"]}</code>
                </pre>
              </section>
            )

          default:
            return (
              <pre className="embedded-entry">
                <code>const Hello = new Gatsby();</code>
              </pre>
            )
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { description, title, file } = node.data.target.fields
        return (
          <div className="embedded-asset">
            <img alt={title["en-US"]} src={file["en-US"].url} />
            <span className="embedded-asset-description">
              {description["en-US"]}
            </span>
          </div>
        )
      },
    },
  }

  const titleOptions = {
    renderMark: {
      [MARKS.ITALIC]: text => (
        <>
          <br />
          <span>{text}</span>
        </>
      ),
    },

    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1
          style={{ textTransform: "uppercase" }}
          className="title jwm-title"
        >{children}</h1>
      ),
    },
  }

class PersonalPortfolio extends Component{
    render(){


      const { contentfulHomePage, allContentfulFamousQuote:FamousQuotes, allContentfulJournalEntry:journals } = this.props.data

      const {
        bio,
        siteName,
        childContentfulHomePageHeaderTitleRichTextNode:headerTitle,
      } = contentfulHomePage

      const BioContent = documentToReactComponents(
        bio.childContentfulHomePageBioContentRichTextNode.json,
        contentOptions
      )

      const SiteHeader = documentToReactComponents(headerTitle.json, titleOptions)

return (
  <Fragment>
    {/* <HeaderTwo logo="symbol-dark" color="color-black" /> */}

    {/* Start Slider Area   */}
    <div className="slider-wrapper">
      <div className="gradient-overlay-CM"></div>
      {/* Start Single Slide */}

      <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image cm-header-img">
        <div style={{ zIndex: 2 }} className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className={`inner text-left`}>
                {siteName ? <span>{siteName}</span> : ""}
                {SiteHeader ? SiteHeader : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Single Slide */}
    </div>
    {/* End Slider Area   */}

    {/* Start About Area */}
    <div className="about-area about-position-top pb--120  bg_color--1">
      <div className="about-wrapper">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-5">
              <div className="thumbnail">
                <img
                  className="w-100"
                  src={`${bio.bioImage.fluid.src}`}
                  alt="John Mercer - Executive Coach"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-inner inner pt--100">
                <div className="section-title">
                  <h2 className="title">{bio.title}</h2>
                  <p className="description">{BioContent}</p>
                </div>
                <div className="row mt--30">
                  <TabTwo data={bio.credentials} tabStyle="tab-style--1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End About Area */}

    {/** Testimonial Area Begin */}
    <div className="about-area about-position-top bg_color--1">
      <h2 className="title text-center pb--60">Micro Lessons</h2>
      <TestimonialOne quotes={FamousQuotes.edges} />
    </div>

    {/* Start Blog Area*/}
    {journals && journals.nodes && journals.nodes.length > 0 && (
      <div className="rn-blog-area ptb--120 bg_color--1">
        <h2 className="title text-center pb--60">Latest Journals</h2>
        <BlogList data={journals} />
      </div>
    )}

    {/* Start Portfolio Area */}
    <div className="portfolio-area pb--120 bg_color--1">
      <ContactThree />
    </div>
    {/* End Portfolio Area */}
  </Fragment>
)
    }
}

export default PersonalPortfolio;
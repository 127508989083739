import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PersonalPortfolio from "./../home/PersonalPortfolio"
import './../index.scss'

const IndexPage = props => (
  <Layout>
    <SEO
      title="Coach Mercer - The most important part of getting started is getting started!"
      image={props.data.contentfulHomePage.headerImage.fixed.src}
      description={props.data.contentfulHomePage.description.description}
      lang="english"
    />
    <PersonalPortfolio data={props.data} />
  </Layout>
)

export const pageQuery = graphql`
         query MyQuery {
           contentfulHomePage {
             description {
               description
             }
             bio {
               bioImage {
                 fluid {
                   src
                 }
               }
               content {
                 content
               }
               title
               childContentfulHomePageBioContentRichTextNode {
                 json
                 content
               }
               credentials {
                 category
                 startDate(formatString: "YYYY")
                 endDate(formatString: "YYYY")
                 subtitle
                 title
               }
             }
             headerImage {
               fixed {
                 src
               }
             }
             headerTitle {
               headerTitle
             }
             siteName
             childContentfulHomePageHeaderTitleRichTextNode {
               json
               content {
                 nodeType
               }
             }
           }

           allContentfulFamousQuote {
             edges {
               node {
                 author
                 childContentfulFamousQuoteQuoteRichTextNode {
                   json
                 }
               }
             }
           }

           allContentfulJournalEntry(
             limit: 6
             sort: { fields: createdAt, order: DESC }
           ) {
             nodes {
               categoryGroup {
                 category
               }
               title
               url
               mainImage {
                 fixed(width: 390, height: 590, cropFocus: CENTER) {
                   src
                 }
               }
             }
           }
         }
       `

export default IndexPage

import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

const Quote = ({ author, active, children }) => {
    return (
        <div className="rn-testimonial-content text-center col-lg-12">
          <div className="inner">
              {children}
          </div>
          <div className="author-info">
            <h6>{author}</h6>
          </div>
        </div>
    );
}

 const quoteOptions = {
   renderMark: {
     [MARKS.ITALIC]: text => (
       <>
         <span>{text}</span>
       </>
     ),
   },

   renderNode: {
     [BLOCKS.PARAGRAPH]: (node, children) => (
       <p>
         {children}
       </p>
     ),
   },
 }

const TestimonialOne = props => {

    const { quotes } = props
    const [currentIndex, setIndex ] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            const newIndex = currentIndex >= props.quotes.length - 1 ? 0 : currentIndex + 1


            setIndex(newIndex)
        }, 10000)

        return () => clearInterval(timer)
    }, [currentIndex])

    return (
      <div className="row cm-quotes">
        <div className="col-lg-12">
          <Tabs selectedIndex={currentIndex} forceRenderTabPanel={true}>
            {quotes.map((quote, id) => {

              const content = documentToReactComponents(
                quote.node.childContentfulFamousQuoteQuoteRichTextNode.json,
                quoteOptions
              )

              return (
                <TabPanel style={{width: '100%'}}>
                  <Quote
                    active={currentIndex === 0}
                    author={quote.node.author}
                  >
                    {content}
                  </Quote>
                  
                </TabPanel>
              )
            })}
            <TabList
              className="testimonial-thumb-wrapper"
              style={{ display: "none" }}
            >

            </TabList>
          </Tabs>
        </div>
      </div>
    );
}

export default TestimonialOne

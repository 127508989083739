import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        const { tabStyle, data:credentials } = this.props

        // This snippet gets me distinct categories!
        const allCategories = [ ... new Set(this.props.data.map( cred => cred.category))]

        return (
          <div>
            {/* Start Tabs Area */}
            <div className="tabs-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Tabs>
                      <TabList className={`${tabStyle}`}>
                        {allCategories.map((category, id) => (
                          <Tab key={id}>{category}</Tab>
                        ))}
                      </TabList>
                      {allCategories.map((category, id) => {
                          const categoryClass = `cm-${category.toLowerCase()}`
                            const credsInCategory = credentials.filter( cred => cred.category === category)
                          return (
                            <TabPanel>
                              <div className={`single-tab-content cm-credentials ${categoryClass}`}>
                                <ul>
                                    {credsInCategory.map( (cred, id) => {
                                        return (
                                          <li key={id}>
                                            <a href='/#'>
                                             {cred.title ? cred.title : ''}
                                              <span>
                                                {" "}
                                                {cred.subtitle ? ` - ${cred.subtitle}` : ''}
                                              </span>
                                            </a>
                                            {cred.startDate ? cred.startDate : ''}  {cred.endDate ? ` - ${cred.endDate}` : cred.startDate ? ' - present' : ''}
                                          </li>
                                        )
                                    })}
                                </ul>
                              </div>
                            </TabPanel>
                          )
                      })}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            {/* End Tabs Area */}
          </div>
        )
    }
}



export default TabsTwo;